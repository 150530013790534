/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Main from './routes/main';
import AddChain from './routes/AddChain';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="addchain" element={<AddChain />} />
        <Route path=":chainId/*" element={<Main />} />
        <Route path="/" element={<Navigate to="v" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

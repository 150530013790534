/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useCallback, useEffect, useState } from 'react';
import { Popover, DropdownBox } from 'tea-component';
import style from './index.module.scss';
// import { GetChainList } from '@src/utils/apis';
import { ChainItem } from '@src/models';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducer } from '@src/store';
export default function chainSelect({
  onChange,
  onInit,
}: {
  onChange: (value: string) => void;
  onInit: (value: string) => void;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { chainId } = useParams();
  const [visible, setVisible] = useState<boolean>(false);
  const { list, currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const updateCurrentChain = useCallback((chain: ChainItem) => {
    dispatch({
      type: 'SET_CURRENTCHAIN',
      payload: chain,
    });
  }, []);
  // const [select, setSelect] = useState<ChainItem | undefined>();
  useEffect(() => {
    dispatch({
      type: 'GET_CHAINS',
      payload: {
        Limit: 99999,
      },
    });
  }, []);
  useEffect(() => {
    if (!list || !list.length) {
      return;
    }
    if (chainId) {
      const chain = list.filter((item) => item.ChainId === chainId);
      if (chain.length) {
        // setSelect(chain[0]);
        updateCurrentChain(chain[0]);
      } else {
        updateCurrentChain(list[0]);
        onInit(list[0].ChainId);
      }
    } else {
      updateCurrentChain(list[0]);
      onInit(list[0].ChainId);
    }
  }, [chainId, list]);
  useEffect(() => {
    if (list && list.length === 0) {
      navigate(`/addchain`, { replace: true });
    }
  }, [list]);

  return (
    <Popover
      visible={visible}
      onVisibleChange={(visible) => setVisible(visible)}
      placement="bottom-start"
      trigger="click"
      overlay={
        <DropdownBox>
          <div className={style.options}>
            {list?.map?.(({ ChainId }) => (
              <div
                className={`${style.option} ${chainId === ChainId ? style.selected : ''}`}
                key={ChainId}
                onClick={() => {
                  onChange(ChainId);
                  setVisible(false);
                }}
              >
                {ChainId}
              </div>
            ))}
          </div>
        </DropdownBox>
      }
    >
      <div className={style.chain_select}>
        <div className={style.value}>{currentChain?.ChainId}</div>
        <div className={style.select_bt}></div>
      </div>
    </Popover>
  );
  // return <Select className="chain-select" listWidth={255} listHeight={40} appearance="button" value={props.value} onChange={(value) => props.onChange(value)} />;
}

/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */
import axios from 'axios';
import { Fetch, ResponseInfo } from './type';
import mock from '../mock';
import { notification } from 'tea-component';
import { transformDesc, transformTitle } from './notification';

// 是否开启mock
const ISMOCK = false;
// mock 情况下接口为一个取配置数据的promise函数
const MOCKFUNC = (url: string) => (params: any, transformResponse?: (resp: ResponseInfo<any>) => void) =>
  new Promise((resolve) => {
    setTimeout(() => {
      transformResponse?.({
        data: mock[url],
        status: 0,
        statusText: '',
        headers: {},
        config: {},
      });
      resolve(mock[url].Response);
    });
  });
// const BASEURL = 'http://192.168.1.108:8888/chainmaker';
const instance = axios.create({
  baseURL: '/chainmaker/', // process.env.NODE_ENV === 'production' ? BASEURL : '/chainmaker',
});
instance.interceptors.response.use(
  (response: ResponseInfo<any>) => {
    // console.log(response);
    const resp: any = response?.data?.Response;
    if (resp?.Error?.Code) {
      notification.error({
        title: transformTitle(response?.config?.params?.cmb),
        description: transformDesc(resp),
        unique: true,
        duration: 3000,
      });
    }
    if (resp) {
      return resp;
    }
  },
  (error) => Promise.reject(error),
);
// 对错误信息进行默认处理的get请求 封装函数
export const createGetChannel = (url: string): Fetch<any, any> => {
  if (ISMOCK) {
    return MOCKFUNC(url);
  }
  return (params: any, transformResponse?: (resp: ResponseInfo<any>) => void) =>
    instance.get('', {
      params: { ...params, cmb: url },
      transformResponse,
    });
};

// 对错误信息进行默认处理的post请求 封装函数
export const createPostChannel = (url: string): Fetch<any, any> => {
  if (ISMOCK) {
    return MOCKFUNC(url);
  }
  return (data: any) =>
    instance.post(
      '',
      {
        ...data,
      },
      {
        params: { cmb: url },
      },
    );
};

// 单纯处理的post请求 的封装函数
export const createPostFetch = (url: string): Fetch<any, any> => {
  if (ISMOCK) {
    return MOCKFUNC(url);
  }
  return (data: any) =>
    axios.post(
      '',
      {
        ...data,
      },
      {
        params: { cmb: url },
      },
    );
};

/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useEffect, useMemo, useState } from 'react';
import PageBox from '@components/ui/PageBox';
import { Form, Bubble } from 'tea-component';
import detailstyle from '../../detail.module.scss';
import { GetContractDetail } from '@src/utils/apis';
import { ContractInfo, EnumContractType } from '@src/models';
import { Navigate, Route, Routes, useParams } from 'react-router';
import Transaction from '../components/Transaction';
import Event from '../components/Event';
import style from './index.module.scss';
import { formatUnixTime } from '@src/utils/tools';
import { Link, NavLink } from 'react-router-dom';
import { RootReducer } from '@src/store';
import { useSelector } from 'react-redux';
import Transfer from '../components/Transfer';

export default function ContractDetail() {
  const { contractName } = useParams();
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  const [detail, setDetail] = useState<ContractInfo | null>(null);
  useEffect(() => {
    GetContractDetail({ ChainId: chainId, ContractName: contractName }).then((res) => {
      if (res.Data) {
        setDetail(res.Data);
      }
    });
  }, [chainId, contractName]);
  return (
    <PageBox title="合约详情">
      <div className={`${detailstyle.detail} ${style.detail}`}>
        <Form.Title>合约基本信息</Form.Title>
        <Form hideLabel={false} fixedLabelWidth={100} layout="inline">
          <Form.Item label="合约名称">
            <Form.Text>{detail?.Name || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="合约状态">
            <Form.Text>{detail?.ContractStatusText || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="合约地址">
            <Form.Text>{detail?.Addr || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="当前版本">
            <Form.Text>{detail?.Version || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="合约类型">
            <Form.Text>{detail?.ContractType || '--'}</Form.Text>
          </Form.Item>
          {detail?.ContractType !== EnumContractType.CMDFA || (
            <Form.Item label="Token简称">
              <Form.Text>{detail?.ContractSymbol || '--'}</Form.Text>
            </Form.Item>
          )}
          {(detail?.ContractType !== EnumContractType.CMDFA && detail?.ContractType !== EnumContractType.CMNFA) || (
            <Form.Item label="发行总量">
              <Form.Text>{detail?.TotalSupply === undefined ? '--' : detail?.TotalSupply}</Form.Text>
            </Form.Item>
          )}
          <Form.Item label="虚拟机类型">
            <Form.Text>{detail?.RuntimeType || '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="创建用户">
            <Form.Text>
              <Bubble content={detail?.CreatorAddr || ''}>
                {authType === 'permissionedwithcert' ? detail?.Creator || '--' : detail?.CreatorAddr || '--'}
              </Bubble>
            </Form.Text>
          </Form.Item>
          <Form.Item label="创建时间">
            <Form.Text>{detail?.CreateTimestamp ? formatUnixTime(detail.CreateTimestamp) : '--'}</Form.Text>
          </Form.Item>
          <Form.Item label="创建交易id">
            <Form.Text>
              {detail?.CreateTxId
                ? (
                  <Link to={`/${chainId}/transaction/${detail.CreateTxId}`}>{detail.CreateTxId}</Link>
                )
                : (
                  '--'
                )}
            </Form.Text>
          </Form.Item>
        </Form>
      </div>
      <div className={style.navs}>
        <NavLink
          to={`/${chainId}/contract/${contractName}/deal`}
          className={({ isActive }) => `${style.nav} ${isActive ? style.act : ''}`}
        >
          交易信息
        </NavLink>
        {(detail?.ContractType !== 'CMDFA' && detail?.ContractType !== 'CMNFA') || (
          <NavLink
            to={`/${chainId}/contract/${contractName}/transfer`}
            className={({ isActive }) => `${style.nav} ${isActive ? style.act : ''}`}
          >
            流转记录
          </NavLink>
        )}
        <NavLink
          to={`/${chainId}/contract/${contractName}/event`}
          className={({ isActive }) => `${style.nav} ${isActive ? style.act : ''}`}
        >
          事件信息
        </NavLink>
      </div>
      <Routes>
        <Route path="deal" element={<Transaction />} />
        <Route path="event" element={<Event />} />
        <Route path="transfer" element={<Transfer type={detail?.ContractType}/>} />
        <Route path="*" element={<Navigate replace to="deal" />} />
      </Routes>
    </PageBox>
  );
}

/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageBox from '@components/ui/PageBox';
import detailstyle from '../detail.module.scss';
import liststyle from '../list.module.scss';
import { GetNFTDetail, GetTransferList } from '@src/utils/apis';
import { Col, Form, Row, Table, TableColumn, Text } from 'tea-component';
import { NFTInfo, TransferItem } from '@src/models';
import { RootReducer } from '@src/store';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatParams, formatUnixTime, emptyToStrikethrough } from '@src/utils/tools';
import style from './index.module.scss';
const { pageable, autotip } = Table.addons;
export default function NFTDetail() {
  const { tokenId, contractName } = formatParams();
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const [detail, setDetail] = useState<NFTInfo>();
  const [list, setList] = useState<TransferItem[]>([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [recordCount, setRecordCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const columns = useMemo<TableColumn[]>(() => {
    const list: TableColumn<TransferItem>[] = [
      {
        key: 'BlockTime',
        header: '上链时间',
        align: 'left',
        render: ({ BlockTime }) => formatUnixTime(BlockTime),
        width: 180,
      },
      {
        key: 'TxId',
        header: '交易Id',
        align: 'left',
        width: 220,
        render: ({ TxId }) => <Link to={`/${chainId}/transaction/${TxId}`}>{TxId}</Link>,
      },
      {
        key: 'ContractMethod',
        header: '合约方法',
        align: 'left',
        render: ({ ContractMethod }) => ContractMethod || '--',
      },
      {
        key: 'From',
        header: '从',
        align: 'left',
        render: ({ From }) => From || '--',
      },
      {
        key: 'To',
        header: '转到',
        align: 'left',
        render: ({ To }) => To || '--',
      },
      {
        key: 'TxStatusCode',
        header: '交易状态',
        align: 'left',
        width: 100,
        render: ({ Status }) => <Text theme={Status === 'SUCCESS' ? 'success' : 'danger'}>{Status}</Text>,
      },
    ];
    return list;
  }, []);
  const getList = useCallback(() => {
    setIsLoading(true);
    GetTransferList({
      ChainId: chainId,
      ContractName: contractName,
      TokenId: tokenId,
      Limit: pageSize,
      Offset: pageIndex - 1,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.GroupList) {
          setList(res.GroupList);
          setRecordCount(res.TotalCount);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [chainId, pageIndex, pageSize]);
  useEffect(() => {
    if (!chainId || !tokenId) {
      return;
    }
    GetNFTDetail({ ChainId: chainId, ContractName: contractName, TokenId: tokenId }).then((res) => {
      if (res.Data) {
        setDetail(res.Data);
      }
    });
  }, [chainId, tokenId, contractName]);
  useEffect(() => {
    getList();
  }, [pageIndex, pageSize]);
  return (
    <PageBox title="NFT详情">
      <div className={detailstyle.detail}>
        <Form.Title>NFT基本信息</Form.Title>
        <Row>
          <Col span={10}>
            {detail?.Url
              ? (
                <div className={style.nft_img}>
                  <img src={detail.Url} />
                </div>
              )
              : (
                <div className={style.nft_img_auto}></div>
              )}
          </Col>
          <Col span={14}>
            <Form hideLabel={false} fixedLabelWidth={100} layout="fixed">
              <Form.Item label="ID">
                <Form.Text>{emptyToStrikethrough(`${tokenId || detail?.TokenId}`)}</Form.Text>
              </Form.Item>
              <Form.Item label="当前持有者">
                <Form.Text>{emptyToStrikethrough(detail?.Owner)}</Form.Text>
              </Form.Item>
              <Form.Item label="生成时间">
                <Form.Text>{formatUnixTime(detail?.CreateTime)}</Form.Text>
              </Form.Item>
              <Form.Item label="作品名称">
                <Form.Text>{emptyToStrikethrough(detail?.Name)}</Form.Text>
              </Form.Item>
              <Form.Item label="作者名">
                <Form.Text>{emptyToStrikethrough(detail?.Author)}</Form.Text>
              </Form.Item>
              <Form.Item label="作品URL">
                <Form.Text>{emptyToStrikethrough(detail?.Url)}</Form.Text>
              </Form.Item>
              <Form.Item label="作品hash">
                <Form.Text>{emptyToStrikethrough(detail?.Hash)}</Form.Text>
              </Form.Item>
              <Form.Item label="作品描述">
                <Form.Text>{emptyToStrikethrough(detail?.Describe)}</Form.Text>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Form.Title>流转记录</Form.Title>
        <Table
          className={liststyle.table}
          compact={false}
          records={list}
          recordKey="TxId"
          bordered={true}
          disableTextOverflow={true}
          columns={columns}
          addons={[
            autotip({
              isLoading,
            }),
            pageable({
              recordCount,
              pageIndex,
              pageSize,
              onPagingChange: (query) => {
                if (query?.pageIndex) {
                  setPageIndex(query.pageIndex);
                }
                if (query?.pageSize) {
                  setPageSize(query.pageSize);
                }
              },
            }),
          ]}
        />
      </div>
    </PageBox>
  );
}

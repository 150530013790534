/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

export const formatAddress = (str: string) => str.replace(/\/ip4\//g, '').replace(/\/tcp\//g, ':');

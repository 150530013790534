import { ControllerFieldState } from 'react-hook-form';
import { FormControlProps } from 'tea-component/lib/form/FormControl';

export function getStatus({
  fieldState,
  isSubmitted,
  isValidating
}: {
  fieldState: ControllerFieldState;
  isSubmitted?: boolean;
  isValidating: boolean;
}): FormControlProps['status'] {
  if (isValidating) {
    return 'validating';
  }
  if (isSubmitted !== undefined) {
    if (!fieldState.isDirty && !isSubmitted && !fieldState.error?.message) {
      return undefined;
    }
  }
  return fieldState.invalid ? 'error' : 'success';
}

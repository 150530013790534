/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Home from './Home';
import Chain from './Chain';
import Block from './Block';
import BlockDetail from './Block/Detail';
import Transaction from './Transaction';
import TransactionDetail from './Transaction/Detail';
import Contract from './Contract';
import ContractDetail from './Contract/Detail';
import Node from './Node';
import Origin from './Origin';
import User from './User';
import style from './index.module.scss';
import { Routes, Route, Navigate, useNavigate, Link } from 'react-router-dom';
import ChainSelect from './components/ChainSelect';
import ImgChainMaker from '@imgs/chainmaker.png';
import ImgFlower from '@imgs/flower.png';
import TWEEN from '@tweenjs/tween.js';
import { useSelector } from 'react-redux';
import { RootReducer } from '@src/store';
import { GetChainConfig } from '@src/utils/apis';
import NFTDetail from './Transaction/NFTDetail';
// import { GetChainId } from '@src/utils/apis';
function animate(time: any) {
  requestAnimationFrame(animate);
  TWEEN.update(time);
}
requestAnimationFrame(animate);
export default function Main() {
  const navigate = useNavigate();
  const [hasChainSetting, setHasChainSeeting] = useState<boolean>(false);
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const updateChainId = useCallback((value) => {
    window.open(`/${value}/home`);
  }, []);
  const onInit = useCallback((value) => {
    setTimeout(() => {
      navigate(`/${value}/home`, { replace: true });
    }, 0);
  }, []);
  useEffect(() => {
    GetChainConfig({}).then((res) => {
      setHasChainSeeting(res.Data);
    });
  }, []);
  return (
    <>
      <div className={style.header}>
        <div className={style.header_c}>
          <Link to={`/${chainId}/home`}>
            <img src={ImgChainMaker} className={style.header_logo} />
          </Link>
          <div className={style.header_setting}>
            <ChainSelect onChange={updateChainId} onInit={onInit} />
            {hasChainSetting && (
              <img
                onClick={() => navigate(`/${chainId}/chain`)}
                src={ImgFlower}
                className={style.header_seeting_icon}
              />
            )}
          </div>
        </div>
      </div>
      {chainId && chainId !== 'v' && (
        <Routes>
          <Route path="home" element={<Home />} />
          <Route path="node" element={<Node />} />
          {hasChainSetting && <Route path="chain" element={<Chain />} />}
          <Route path="block" element={<Block />} />
          <Route path="block/:blockHeight" element={<BlockDetail />} />
          <Route path="transaction" element={<Transaction />} />
          <Route path="transaction/:txId" element={<TransactionDetail />} />
          <Route path="nft" element={<NFTDetail />} />
          <Route path="contract" element={<Contract />} />
          <Route path="contract/:contractName/*" element={<ContractDetail />} />
          <Route path="origin" element={<Origin />} />
          <Route path="user" element={<User />} />
          <Route path="*" element={<Navigate to="home" />} />
        </Routes>
      )}
    </>
  );
}

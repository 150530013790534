/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import { ChangeContext } from 'tea-component/lib/form/controlled';
import { Input } from 'tea-component';
import React, { useCallback } from 'react';
import style from './index.module.scss';

export default function SearchInput({
  bt,
  value,
  onChange,
  placeholder,
  onSubmit,
}: {
  bt?: string | undefined;
  value?: string;
  onChange?: (value: string, context?: ChangeContext<React.SyntheticEvent<Element, Event>>) => void;
  placeholder?: string;
  onSubmit?: () => void;
}) {
  const onKeyPress = useCallback(
    (e) => {
      if (e.which === 13 && onSubmit) {
        onSubmit();
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [onSubmit],
  );
  return (
    <div className={style.c}>
      <div className={style.b}>
        <div className={style.input}>
          <Input
            style={{ height: 40, fontSize: 14 }}
            size="full"
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
        </div>
        <div className={style.bt} onClick={onSubmit}>
          {bt || '筛选'}
        </div>
      </div>
    </div>
  );
}

/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React from 'react';
import style from './index.module.scss';
import { Justify } from 'tea-component';
export default function Between(props: { title: string | React.ReactNode; content: React.ReactNode }) {
  return (
    <Justify
      left={
        <div className={style.left}>
          <div className={style.left_line}></div>
          {props.title}
        </div>
      }
      right={props.content}
    />
  );
}

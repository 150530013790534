/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import style from './index.module.scss';
import Between from '@components/ui/Between';
import { Tx } from '@src/models';
import { GetTxList } from '@src/utils/apis';
import { Link } from 'react-router-dom';
import { formatHashDetail, formatUnixWithoutYear } from '@src/utils/tools';
import { Bubble, Icon } from 'tea-component';
import right from '@imgs/right.png';
import TWEEN from '@tweenjs/tween.js';
import { useSelector } from 'react-redux';
import { RootReducer } from '@src/store';
let timeManage: any;
export default function Deal() {
  const { currentChain } = useSelector((state: RootReducer) => state.chainReducer);
  const chainId = useMemo(() => currentChain?.ChainId, [currentChain]);
  const authType = useMemo(() => currentChain?.AuthType, [currentChain]);
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [txs, setTxs] = useState<Array<Tx | null>>([null, null, null, null, null, null, null, null, null, null]);
  const [listTop, setListTop] = useState<number>(0);
  // 触发开始递归刷新数据
  const triggerUpdate = useCallback(() => {
    clearTimeout(timeManage);
    timeManage = setTimeout(() => {
      setRefreshCount(refreshCount + 1);
    }, 3000);
  }, [refreshCount]);
  /**
   * @param 更新的列表数据
   * 开始
   */
  const moveList = useCallback(
    (list: Array<Tx | null>) => {
      if (list.length) {
        // 记录久数组第一条数据对于新数组中的位置
        let number: number | null = null;
        let newTxs = list;
        for (let i = 0; i < 10; i++) {
          const tx = list[i];
          if (tx?.TxId === txs[0]?.TxId) {
            number = i;
            break;
          }
        }
        if (number === null) {
          number = newTxs.length;
          newTxs = newTxs.concat(txs);
        } else {
          newTxs = newTxs.slice(0, number).concat(txs);
        }
        const left = number * -98;
        setTxs(newTxs);
        setListTop(left);
        new TWEEN.Tween({ left })
          .to({ left: 0 }, 1000)
          .duration(1500)
          .easing(TWEEN.Easing.Cubic.Out)
          .onUpdate((info) => {
            setListTop(info.left);
            if (info.left === 0) {
              setTxs(newTxs.slice(0, 10));
            }
          })
          .start();
      }
      triggerUpdate();
    },
    [txs, triggerUpdate],
  );
  const updateList = useCallback(() => {
    GetTxList({
      ChainId: chainId,
      Limit: 10,
    }).then((res) => {
      if (res.GroupList) {
        moveList(res.GroupList);
        // setTxs([]);
      }
    });
  }, [chainId, moveList]);
  const stopMove = useCallback(() => {
    clearTimeout(timeManage);
  }, []);
  useEffect(() => {
    updateList();
    return () => {
      stopMove();
    };
  }, [refreshCount]);
  return (
    <div className={style.box}>
      <Between
        title={
          <>
            最新交易
            <Bubble arrowPointAtCenter placement="top-start" content="表示该笔交易是哪个链账户调用哪个智能合约产生的。">
              <Icon type="info" className={style.bubble} />
            </Bubble>
          </>
        }
        content={
          <Link className={style.more} to={`/${chainId}/transaction`}>
            更多
            <img src={right} />
          </Link>
        }
      />
      <div className={style.list}>
        <div style={{ top: listTop }} className={style.deal_list} onMouseEnter={stopMove} onMouseLeave={triggerUpdate}>
          {txs.map(
            (
              tx,
              index, // { Sender, ContractName, Timestamp, TxId }
            ) =>
              tx
                ? (
                  <div key={index} className={style.item}>
                    <div className={`${style.title}`}>
                      <Link to={`/${chainId}/transaction/${tx.TxId}`}>{formatHashDetail(tx.TxId)}</Link>
                      <div className={style.time}>{formatUnixWithoutYear(tx.Timestamp)}</div>
                    </div>
                    <div className={style.info}>
                      <div className={style.path}>
                        <Bubble content={tx.UserAddr}>
                          {authType === 'permissionedwithcert' ? tx.Sender || '--' : tx.UserAddr || '--'}
                        </Bubble>
                      </div>
                      <div className={style.point}></div>
                      <div className={style.name}>
                        <Bubble content={tx.ContractName}>{tx.ContractName}</Bubble>
                      </div>
                    </div>
                  </div>
                )
                : (
                  <div key={index} className={style.item}></div>
                ),
          )}
          {txs.length === 0 && <div className="empty_list"></div>}
        </div>
      </div>
    </div>
  );
}
